import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import CPORestData from '../Data/CPORestData'
import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'

const AppCPORestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .CPOContentSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .CPOHeadingEmail {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .CPOLeadParaStyleOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .CPOLeadParaStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .CPOLeadParaSubPointStyle {
    color: #047cc2;
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .achorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 0px;
    text-decoration: none;
  }
  .achorPara:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .PointColor {
    font-size: 22px;
    font-weight: bold;
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
`

export const AppCPORest = () => {
  return (
    <AppCPORestWapper>
      <div>
        {CPORestData.map((data, index) => {
          return (
            <div className='MainRFPProcessNeedsSection' key={index}>
              <div className='CPOContentSectionEmail'>
                <ReUsePtag para={data.ParaOne} paraStyle='CPOLeadParaStyleOne' />
                <ReUsePtag para={data.ParaTwo} paraStyle='CPOLeadParaStyle' />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaThreeBefor}
                  anchorPara={data.ParaThreeAnchor}
                  ParaRight={data.ParaThreeAfter}
                  href={data.AnchorLink}
                  target='_blank'
                  rel='noopener noreferrer'
                  anchorStyle='achorPara'
                  paraStyle='CPOLeadParaStyle'
                />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.ParaFourPointOne}
                  paraStyle='CPOLeadParaStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.ParaFourPointTwo}
                  paraStyle='CPOLeadParaStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.ParaFourPointThree}
                  paraStyle='CPOLeadParaStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.ParaFourPointFour}
                  paraStyle='CPOLeadParaStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaFive} paraStyle='CPOLeadParaStyle' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.ParaFivePointOne}
                  paraStyle='CPOLeadParaStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.ParaFivePointTwo}
                  paraStyle='CPOLeadParaStyle'
                  IconStyle='iconColor'
                />
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle='CPOHeadingEmail' />
                <ReUsePtag para={data.ParaSix} paraStyle='CPOLeadParaStyle' />
                <ReUsePtag para={data.ParaSeven} paraStyle='CPOLeadParaStyle' />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle='CPOHeadingEmail' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingTwoPointOne}
                  paraStyle='CPOLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                 <ReUsePtag para={data.ParaEight} paraStyle='CPOLeadParaStyle' />
                 <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingTwoPointTwo}
                  paraStyle='CPOLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                 <ReUsePtag para={data.ParaNine} paraStyle='CPOLeadParaStyle' />
                 <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingTwoPoinThree}
                  paraStyle='CPOLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                 <ReUsePtag para={data.ParaTen} paraStyle='CPOLeadParaStyle' />
                 <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingTwoPointFour}
                  paraStyle='CPOLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                 <ReUsePtag para={data.ParaEleven} paraStyle='CPOLeadParaStyle' />
              </div>
            </div>
          )
        })}
      </div>
    </AppCPORestWapper>
  )
}
