import React from 'react'
import styled from 'styled-components'
import { AppSideNavEmailName } from './AppSideNavEmailName'
import { AppCPORest } from './AppCPORest'

const AppCPOWithNavWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .CPOSection {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 15px;
    }
  }
  .CPONav {
    order: 0;
    @media (max-width: 1090px) {
      order: 1;
      margin: auto;
    }
  }
  .CPOSectionContent {
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
`

export const AppCPOWithNav = () => {
  return (
    <AppCPOWithNavWapper>
      <div className='CPOSection'>
        <div className='CPONav'>
          <AppSideNavEmailName />
        </div>
        <div className='CPOSectionContent'>
          <AppCPORest />
        </div>
      </div>
    </AppCPOWithNavWapper>
  )
}
