import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    PointPara: '⟶',
    ParaOne: "CPO’s are focusing on the need to build new capabilities and create a digitally-enabled ecosystem as a core differentiating factor.",
    ParaTwo: "With Raindrop’s spend management platform, realize rapid value attainment to support your entire enterprise spend management digitization needs from Planning, Sourcing, Contracting, and more. Artificial Intelligence (AI) capabilities to digitize your procurement artifacts and Machine Learning(ML) capabilities to predict and forecast your spend patterns. Utilize Raindrop’s toolkit capabilities to replace your business fulfillment costs via technology while addressing your global language and compliance needs.",
    ParaThreeBefor: "",
    ParaThreeAnchor: "Deloitte’s 2021 CPO survey",
    AnchorLink: "https://www2.deloitte.com/us/en/insights/topics/operations/chief-procurement-officer-cpo-survey.html",
    ParaThreeAfter: "revealed the following as the top priorities for CPOs. This is where Raindrop’s Enterprise Spend Management solution comes in, which helps turn your Procurement Department into a value-generating team by addressing and fulfilling the top priorities.",
    ParaFourPointOne: "Driving operational efficiencies",
    ParaFourPointTwo: "Reducing costs",
    ParaFourPointThree: "Digital transformation",
    ParaFourPointFour: "Delivering innovation through procurement",
    ParaFive: "The article covers",
    ParaFivePointOne: "Introduction to Raindrop",
    ParaFivePointTwo: "Raindrop helps achieve critical digitization initiatives for Procurement leaders",
    HeadingOne: "Introduction to Raindrop",
    ParaSix: "Raindrop is an Enterprise Spend Management business process fulfillment platform which enables businesses to start and realize their value journey with modern spend management technology. Automate and control the entire Source to Pay cycle, from spend planning, sourcing, contracting, ordering, and back through the renewal. Raindrop provides spend visibility across our integrated modules, and identifies opportunities for cost savings and cost containment, delivering on the necessary functionality to fulfill your Procurement processes and harness the value of your investments.",
    ParaSeven: "Raindrop’s frictionless and integrated platform architecture was designed from the ground up to provide deeper capabilities through Artificial Intelligence (AI) and Machine learning (ML), offering rapid insights on external spend from planning through operation. This enables your company to reduce the various digital Procurement point solution systems footprint, while actively streamlining processes and recouping thousands of dollars in operational and capital expenses.",
    HeadingTwo: "Raindrop helps achieve critical digitization initiatives for Procurement leaders",
    HeadingTwoPointOne: "Driving Operational Efficiency", 
    ParaEight: "Capture your existing workflows or easily set up new workflows to create a streamlined operational function with your sourcing processes, resulting in cross-functional benefits of having everyone working in a single-source of truth environment. With Raindrop, your team will have instant access to the spend data and contractual commitments. Raindrop’s natively inbuilt collaboration feature supports immediate communication with internal teams and external suppliers, to create a more efficient and operational sound procurement process.",
    HeadingTwoPointTwo: "Digital Transformation",
    ParaNine: "Raindrop’s artificial intelligence (AI) and machine learning(ML) capabilities digitize your procurement artifacts. From spend analytics, spend planning, sourcing, contract lifecycle management to supplier relationship management - Raindrop streamlines the entire “Plan through Payment and Renewal” process, providing real-time access to market data and trends, advanced analytics, risks, etc., reinforcing the delivery of immediate strategic value.",
    HeadingTwoPoinThree: "Delivering Innovation Through Procurement",
    ParaTen: "Raindrop continuously enables new value streams and innovations to expand your strategic advantage. Supporting your corporate social responsibility (CSR) program, supplier diversity and inclusion, and sustainability programs are also key differentiating factors built directly into the Raindrop platform.  Looking for a diverse supplier is now only a single click away.",
    HeadingTwoPointFour: "Cost Reduction",
    ParaEleven: "Raindrop’s frictionless integration and user experience ensures your team has a unified view of spend and can drive specific actions to maximize the value of your investments. Utilizing Raindrop’s Machine Learning and Artificial Intelligence inbuilt technologies, you can gain immediate spend visibility, and advanced spend intelligence, allowing you to collaborate and deliver on your cost reduction and supplier spend efficiency strategies. Raindrop’s “Spend Recommendation” technology provides insight into market discounting across a large number of commodities, further enabling efficiencies across your enterprise.",
  },
]
